import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const drugStickerGet = async () => {
  //await refreshToken()
  const response = await api
    .get({
      path: 'documentsticker',
    })
    .then(res => (res.data || {}))
    .catch(err => {
      console.log('Get drug sticker err : ', err)

      return {}
    })

  return response
}
const drugStickerUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'documentsticker',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('Update drug sticker err : ', err)

      return {}
    })

  return response
}
export default { drugStickerGet, drugStickerUpdate }
