<template>
  <div>
    <v-card :loading="loading">
      <v-card-title>
        {{ $t('Set up Medicine labels') }} <small class="ml-2 error--text">{{ $t('suggestion_sticker') }}</small>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model.trim="documentsticker.sticker_font_size"
              :label="$t('font_size_recommended')"
              type="number"
              outlined
              dense
              @keypress.enter="updateSrugSticker"
            ></v-text-field>
            <v-text-field
              v-model.trim="documentsticker.sticker_width"
              :label="$t('drug_label_width')"
              type="number"
              dense
              outlined
            ></v-text-field>
            <v-text-field
              v-model.trim="documentsticker.sticker_height"
              :label="$t('drug_label_length')"
              type="number"
              dense
              outlined
            ></v-text-field>
            <v-row>
              <v-col cols="12" md="6">
                <v-checkbox
                  v-model.trim="documentsticker.sticker_show_name"
                  :label="$t('show_shop_name')"
                  :true-value="'1'"
                  :false-value="'0'"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="6">
                <v-checkbox
                  v-model.trim="documentsticker.sticker_show_address"
                  :label="$t('show_address')"
                  :true-value="'1'"
                  :false-value="'0'"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="6">
                <v-checkbox
                  v-model.trim="documentsticker.sticker_show_tel"
                  :label="$t('show_phone_number')"
                  :true-value="'1'"
                  :false-value="'0'"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="6">
                <v-checkbox
                  v-model.trim="documentsticker.sticker_show_date"
                  :label="$t('show_date_print')"
                  :true-value="'1'"
                  :false-value="'0'"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="6">
                <v-checkbox
                  v-model.trim="documentsticker.sticker_show_detail"
                  :label="$t('showDetail')"
                  :true-value="'1'"
                  :false-value="'0'"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="6">
                <v-checkbox
                  v-model.trim="documentsticker.sticker_show_expdate"
                  :label="$t('showExpireDate')"
                  :true-value="'1'"
                  :false-value="'0'"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="12" md="6" class="py-0">
            <!-- <span class="text-lg font-weight-bold">{{ $t('sample') }}</span> -->
            <div align="center">
              <v-card
                v-if="!loading"
                elevation="5"
                class="set-new-font"
                outlined
                :width="`${documentsticker.sticker_width}mm`"
                :height="`${documentsticker.sticker_height}mm`"
              >
                <div align="left">
                  <v-card-text :style="`font-size:${documentsticker.sticker_font_size}px;`" class="px-2">
                    <div v-if="documentsticker.sticker_show_date == '1'" align="right">
                      <span>
                        {{ date | sumdate }}
                      </span>
                    </div>
                    <span
                      v-if="documentsticker.sticker_show_name == '1'"
                      :style="`font-size:${+documentsticker.sticker_font_size + 2}px;`"
                      class="font-weight-bold"
                    >
                      {{ shopData.shop_name }} <br />
                    </span>
                    <span v-if="documentsticker.sticker_show_address == '1'">
                      {{ shopData.shop_address }} {{ shopData.shop_district }} {{ shopData.shop_amphoe }}
                      {{ shopData.shop_province }} {{ shopData.shop_zipcode }} <br />
                    </span>
                    <span v-if="documentsticker.sticker_show_tel == '1'">โทร {{ shopData.shop_tel }} <br /> </span>
                    <div class="">นาย ใจดี มีสุข</div>
                    <span> พาราเซตามอล (D001) #1 แพ็ค </span>
                    <br />
                    <span v-if="documentsticker.sticker_show_expdate == '1'"> (EXP : {{ date | sumdate }}) <br /></span>

                    <span> วิธีใช้ : กิน เช้า -กลางวัน- เย็น </span>
                    <br />
                    <span v-if="documentsticker.sticker_show_detail == '1'"> คำแนะนำ : กินอย่างน้อย 1 ชุด ต่อวัน </span>
                  </v-card-text>
                </div>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" :loading="updateloading" :disabled="updateloading" @click="updateSrugSticker()">
          {{ $t('save') }}
        </v-btn>
        <v-btn color="secondary" outlined @click="getDrugSticker">
          {{ $t('default') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import drugSticker from '@/api/drugAndCert/drugSticker'
import store from '@/store'
import { sumdate } from '@/plugins/filters'
import shop from '@/api/shop'

export default {
  filters: {
    sumdate,
  },
  setup() {
    const documentsticker = ref({})
    const loading = ref(false)
    const updateloading = ref(false)
    const moment = require('moment')
    const date = ref(moment(new Date()).format('YYYY-MM-DD'))
    const shopData = ref({})
    const { drugStickerGet, drugStickerUpdate } = drugSticker

    shop.shop().then(res => {
      shopData.value = res
    })

    onMounted(() => {
      loading.value = true
      getDrugSticker()
    })

    const updateSrugSticker = () => {
      updateloading.value = true
      drugStickerUpdate(documentsticker.value).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        updateloading.value = false
        getDrugSticker()
      })
    }

    const getDrugSticker = () => {
      loading.value = true
      drugStickerGet().then(res => {
        documentsticker.value = res
        loading.value = false
      })
    }

    return {
      documentsticker,
      loading,
      updateloading,
      updateSrugSticker,
      getDrugSticker,
      date,
      shopData,
    }
  },
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Niramit:wght@300;400;700&display=swap');
.set-new-font {
  font-family: 'Niramit', sans-serif;
}
</style>
